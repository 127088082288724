


















































import Vue from 'vue';
import {Backend} from "api-frontend";
import {AudioInformation, AudioPlayer} from '@/services/AudioPlayer';

export default Vue.extend({
  name: 'Bell',
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      showSnackbar: false,
      snackBarContent: "",
      audio: undefined as AudioPlayer | undefined,
      training: true,
    }
  },
  created: function () {
    const audioSettings: AudioInformation = {
      audioFile: "/klingeln/lehraudio.mp3",
      ringingPoints: [
        {
          startTime: 61,
          endTime: 63
        },
        {
          startTime: 95,
          endTime: 97
        },
        {
          startTime: 135,
          endTime: 137
        }
      ]
    }
    this.audio = new AudioPlayer(audioSettings, this.backend, (status) => {
      switch (status) {
        case "Incorrect":
          this.showMessage("Leider falsch :/\nProbiers gleich nochmal ;)");
          this.handleWrongRinging();
          break;
        case "TooLate":
          this.showMessage("Leider zu spät geklingelt :/\nVersuchs doch gleich nochmal ;)");
          this.handleWrongRinging();
          break;
        case "Correct":
          this.showMessage("Alles richtig :)")
          break;
      }
      this.$forceUpdate();
    })
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.reset();
    }
  },
  methods: {
    showMessage(message: string) {
      if (this.showSnackbar) {
        return;
      }
      this.snackBarContent = message;
      this.showSnackbar = true;
    },
    handleWrongRinging() {
      if (this.training) {
        return;
      }
      this.audio?.reset();
    }
  }
})
