import {getResourceUrl} from "@/services/getResource";
import {Backend} from "api-frontend";
import {randomInt} from "mp-backend/src/helper/random";

export type AudioInformation = {
    audioFile: string,
    ringingPoints: RingingPoint[]
}

export type RingStatus = "TooLate" | "Incorrect" | "Correct"

type RingingPoint = {
    startTime: number,
    endTime: number,
}

export class AudioPlayer {
    audio: HTMLAudioElement;
    updateFunc: (status?: RingStatus) => void;
    ringingPoints: RingingPoint[];
    ringingAmount: number;
    backend: Backend;

    constructor(audio: AudioInformation, backend: Backend, updateFunc: (status?: RingStatus) => void) {
        if (audio.ringingPoints.length === 0) {
            throw new Error("Empty ringing points not allowed")
        }
        this.audio = new Audio(getResourceUrl(audio.audioFile, backend));
        this.updateFunc = updateFunc;
        this.ringingPoints = audio.ringingPoints;
        this.ringingAmount = 0;
        this.audio.onended = () => this.updateFunc();
        this.backend = backend
    }

    public playAudio() {
        if (!this.paused()) {
            return;
        }
        this.audio.play();
        this.checkRinged();
        this.updateFunc();
    }

    public reset() {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.ringingAmount = 0;
        this.updateFunc();
    }

    public pauseAudio() {
        this.audio.pause();
        this.updateFunc();
    }

    public fastForward() {
        if (this.paused()) {
            return;
        }
        const currentTime = this.audio.currentTime;
        let nextRingingPoint: RingingPoint | undefined = undefined;
        this.ringingPoints.forEach((point) => {
            if (point.endTime > currentTime && (!nextRingingPoint || nextRingingPoint.startTime > point.startTime)) {
                nextRingingPoint = point;
            }
        })
        // @ts-ignore
        if (nextRingingPoint.startTime > currentTime + 10) {
            // @ts-ignore
            this.audio.currentTime = nextRingingPoint.startTime - randomInt(2, 10)
        }
    }

    public ring() {
        const ringEffect = new Audio(getResourceUrl("/klingeln/ringEffect.mp3", this.backend));
        ringEffect.volume = 0.5;
        ringEffect.play();

        const currentTime = this.audio.currentTime;
        if (this.ringingPoints.find((point) => point.startTime <= currentTime && point.endTime >= currentTime)) {
            this.ringingAmount++;
            this.updateFunc("Correct")
        } else {
            this.updateFunc("Incorrect")
        }
    }

    public paused = () => this.audio.paused
    public resetPossible = () => this.audio.currentTime !== 0

    checkRinged() {
        if (this.audio.paused) {
            return;
        }
        const currentTime = this.audio.currentTime;
        const pastRingingPoints = this.ringingPoints.filter((point) => point.endTime < currentTime)
        if (pastRingingPoints.length > this.ringingAmount) {
            this.ringingAmount++;
            this.updateFunc("TooLate");
        }
        setTimeout(() => this.checkRinged(), 1000);
    }
}